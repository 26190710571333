<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>공지사항</sub-title>
                <div class="main_panel">
                    <div class="notice">
                        <div class="notice_list" v-for="(n,index) in noticeList" :key="index">
                            <div class="n">
                                {{index+1}}
                            </div>
                            <div class="t" :style="{'color':n.titleColor}" @click="showContent(n.id)">
                                <span v-html="n.title"></span>
                            </div>
                            <div class="c" v-if="n.contentShow && null != n.content" v-html="n.content">
                            </div>
                            <div class="c" style="text-align: center;padding: 30px 10px 10px 10px"  v-if="n.contentShow">
                                <img v-if="null != n.eventImg" :src="n.eventImg" alt="공지사항">
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>
</template>

<script>
    import {getNoticeList} from "../../network/userRequest";
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import RightBarComp from "../../components/RightBarComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";
    import SubTitle from "../../components/SubTitle";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";

    export default {
        name: "Notice",
        mixins:[postionMixin],
        components: {
            RightButtonsComp,
            UserInfoComp,
            SubTitle,
            FootComp,
            RightBarBannerComp, SportsBetCartComp, RightBarComp, SportsLeftBarComp, LeftBarComp, TopbarComp
        },
        data() {
            return {
                sportsConst,
                noticeList: [],
                clickNumber: 0,
                position:"공지사항",
            }
        },
        methods: {
            showContent(id){
                if(id === this.clickNumber){
                    this.clickNumber = 0
                }else {
                    this.clickNumber = id;
                }
                this.noticeList.map(item=>{
                    if(item.id == id){
                        this.$set(item,'contentShow',!item.contentShow)
                    } else {
                        this.$set(item,'contentShow',false)
                    }

                })
            }
        },
        created() {
            this.$store.commit(RECEIVE_SHOW_LOADING)
            this.clickNumber = this.$route.query.id
            console.log(this.clickNumber)
            /*공지사항추출*/
            getNoticeList(100).then(res => {
                if (res.data.success) {
                    this.noticeList = res.data.data
                    this.noticeList.map(item=>{
                        if(item.id == this.clickNumber){
                            this.$set(item,'contentShow',true)
                        } else {
                            this.$set(item,'contentShow',false)
                        }
                    })
                }
                this.$store.commit(RECEIVE_HIDE_LOADING)
            })
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/notice.css");
    .notice_list .c img{
        max-width:750px ;
    }
    @media screen and (max-width: 1024px) {
        .notice_list .c img{
            width: 100% !important;
            max-width:420px ;
        }
    }
</style>